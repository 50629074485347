<template>
  <div
    class="tab-pane fade"
    id="cart-setting"
    role="tabpanel"
    aria-labelledby="cart-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Sepet Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />

      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Remember -->
          <WeSettingCard
            class="mb-3"
            name="remember"
            title="Sepeti Hatırlatma"
            description="Eğer sepette ürün varsa ve alışveriş tamamlanmadıysa otomatik hatırlatma maili gönder"
            v-model="data.remember"
          />
          <!-- Remember -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Update -->
          <WeSettingCard
            class="mb-3"
            name="update"
            title="Sepet Fiyatları Güncellensin"
            v-model="data.cart_update"
          />
          <!-- Update -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Piece Price -->
          <WeSettingCard
            name="show-piece-price"
            title="Birim Fiyat Göster"
            v-model="data.show_piece_price"
          />
          <!-- Piece Price -->
        </div>
      </div>

      <div class="row align-items-center" v-if="data.cart_update">
        <span class="col-auto pr-0 custom-label">Sepet fiyatlarını </span>
        <div class="col-auto">
          <input
            style="max-width: 50px"
            min="1"
            type="number"
            class="form-control form-control-sm"
            v-model="data.update_period"
          />
        </div>
        <span class="custom-label">dakikada bir güncelle.</span>
      </div>

      <hr />

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label for="min-amount" class="custom-label"
                >Minimum Ürün Miktarı</label
              >
              <div class="d-block">
                <small class="text-muted"
                  >Sepette bulunması gereken minimum ürün miktarı</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                type="text"
                id="min-amount"
                min="1"
                v-model="data.min_amount"
                v-bind:formGroup="false"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-row align-items-center mb-3">
            <div class="col-12 col-lg">
              <label for="min-amount" class="custom-label"
                >Minimum Sepet Tutarı</label
              >
              <div class="d-block">
                <small class="text-muted"
                  >Sepette bulunması gereken minimum toplam tutar</small
                >
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <WeInput
                class="mb-0"
                id="min-cart-price"
                v-model="data.min_price"
                v-bind:filter-number="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <h5>Sepet Tab Düzenleme</h5>
          <hr />
          <!-- Update -->
          <label class="custom-label">Düzenleme İstediğiniz Tab Seçiniz</label>
          <v-select
            label="title"
            v-bind:options="getCartTabList"
            v-bind:reduce="(opt) => opt.key"
            v-bind:clearable="false"
            v-on:input="onSelectTab"
            class="custom-v-select-sm"
          >
          </v-select>
          <div
            class="mt-2"
            v-for="(item, index) in data.cart_tab_list"
            v-bind:key="index"
            v-if="item['key'] == selected_tab"
          >
            <div class="mt-2">
              <label class="custom-label">Durum</label>
              <WeSwitch v-model="item.is_active" v-bind:show-status="true" />
            </div>
            <div class="mt-2">
              <label class="custom-label">Listelencek Ürünü Sayısı</label>
              <WeInput
                v-model="item.limit"
                v-bind:filter-number="true"
              />
            </div>
          </div>
          <!-- Update -->
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "Cart",
  data() {
    return {
      selected_tab: null,
      data: {
        remember: this.settings["cart.remember"] == "1" ? 1 : 0,
        cart_update: this.settings["cart.update"] == "1" ? 1 : 0,
        cart_tab_list: JSON.parse(this.settings["cart.tab_list"] ?? "{}"),
        show_piece_price: this.settings["cart.show_piece_price"] == "1" ? 1 : 0,
        update_period: this.settings["cart.update"]
          ? this.settings["cart.update_period"]
          : "1",
        min_amount: this.settings["cart.min_amount"],
        min_price: this.settings["cart.min_price"],
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getCartTabList() {
      return JSON.parse(JSON.stringify(this.data.cart_tab_list))
    }
  },
  methods: {
    onSelectTab(e) {
      this.selected_tab = e;
    },
    onSave() {
      this.settings["cart.remember"] = this.data.remember;
      this.settings["cart.tab_list"] = JSON.stringify(this.data.cart_tab_list);
      this.settings["cart.update"] = this.data.cart_update;
      this.settings["cart.show_piece_price"] = this.data.show_piece_price;
      this.settings["cart.update_period"] = this.data.cart_update
        ? this.data.update_period
        : 0;
      this.settings["cart.min_amount"] = this.data.min_amount;
      this.settings["cart.min_price"] = this.data.min_price;
      this.$parent.$emit("on-save", this.settings);
    },
  },
};
</script>
